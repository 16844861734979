import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageService } from '../../../service/storage.service';
import { UtilisateurData} from './UtilisateurData';
import { DroitUtilisateurData} from './DroitUtilisateurData';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  private urlApi:string = environment.baseURLAPI + environment.baseAccountPort + 'account';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }
 
  countAll(): Observable<number> {
    const url = `${this.urlApi}/count`;
    return this.httpClient.get<number>(url);
  }

  getProfils(): Observable<any> {
    const url = `${this.urlApi}/profil/`;
    return this.httpClient.get(url);
  }

  getUtilisateurs(): Observable<any> {
    const url = `${this.urlApi}/list`;
    return this.httpClient.get(url);
  }
  
  getAllAdminUtilisateurs(): Observable<any> {
    const url = `${this.urlApi}/list-admin`;
    return this.httpClient.get(url);
  }

  getUtilisateursByOrganisation(organisationId:string): Observable<any> {
    const url = `${this.urlApi}/list/`;
    return this.httpClient.get(url + organisationId);
  }  

  createUtilisateur(utilisateurData:UtilisateurData): Promise<UtilisateurData>{
	const url = `${this.urlApi}/credentials`;
    console.info(JSON.stringify(utilisateurData));
    return this.httpClient.post(url, utilisateurData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }

  getUtilisateur(accountId:string): Observable<any>{
    const url = `${this.urlApi}/current/`;
      console.info(JSON.stringify(accountId));
      return this.httpClient.get(url + accountId);
  }

    attribuerDroits(droitUtilisateurData:DroitUtilisateurData): Promise<string>{
    const url = `${this.urlApi}/authorities`;
      console.info(JSON.stringify(droitUtilisateurData));
      return this.httpClient.post(url, droitUtilisateurData)
        .pipe(
          map((res: any) => {
            return res;
          })
        ).toPromise();
    }  
}
