import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

export class Commande {
  id: string;
  optionTVA: boolean = false;
  canUpdated: boolean = false;
  montantHT: number;
  montantTVA: number = 0;
  montantTTC: number;
  valeurTVA: number = 0;
  codeStatus: CommandeStatus = CommandeStatus.SAISIE;
  dateEmission: string;
  libelle: string;
  reference: string;
  fournisseur: string;
  typeArticle: string;
  commandeEngager: boolean;
  lignesDeCommande: Array<LigneDeCommande>;
}

export class LigneDeCommande {
  id: string;
  typeArticle: string;
  articleId: string;
  numeroArticle: string;
  designation: string;
  conditionnement: string;
  quantite: number;
  prixUnitaire: number;
  typeArticleId: string;
  montant: number;
  hasTva: boolean;
  optionTVA: boolean;
  valeurTVA: number = 0;
  montantTVA: number = 0;
  montantTTC: number = 0;
}

export const tvaValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const optionTVA = control.get('optionTVA');
  const montantTVA = control.get('montantTVA');
  if (!optionTVA.value) return null;
  let tvaValidated = montantTVA && montantTVA.value ? null : { tvaValidated: true };
  return tvaValidated;
};

export enum CommandeStatus {
  VISER = "VISER",
  REJETER = "REJETER",
  SAISIE = "SAISIE",
  ANNULER = "ANNULER",
}

