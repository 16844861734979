import { Commande, CommandeStatus } from './commande';
import { CommandeFilter } from './commande-filter';
import { Injectable } from '@angular/core';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

const headers = new HttpHeaders().set('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class CommandeService {
  commandeList: Commande[] = [];
  api = environment.baseURLAPI + environment.baseBudgetPort + 'budgetaire/commandes';

  constructor(private http: HttpClient) {
  }

  findById(id: string): Observable<Commande> {
    const url = `${this.api}/${id}`;
    return this.http.get<Commande>(url, { headers });
  }

  findAllCommandeByStatus(status: CommandeStatus): Observable<Commande[]> {
    const url = `${this.api}/status/${status}`;
    return this.http.get<Commande[]>(url, { headers });
  }

  load(filter: CommandeFilter): Promise<Commande[]> {
    return this.find(filter).pipe(
      map((result: Commande[]) => {
        this.commandeList = result;
        return result;
      })
    ).toPromise();
  }

  visaRejetCommande(commandeSelectedList: string[], status: CommandeStatus): Promise<void> {
    const url = `${this.api}/visa-rejet/` + status;
    return this.http.post(url, commandeSelectedList)
      .pipe(
        map((res: any) => { return res; })).toPromise();
  }

  find(filter: CommandeFilter): Observable<Commande[]> {
    const params = {
    };

    return this.http.get<Commande[]>(this.api, { params, headers });
  }

  save(entity: Commande): Observable<Commande> {
    let params = new HttpParams();
    let url = '';
    if (entity.id) {
      url = `${this.api}`;
      //params = new HttpParams().set('ID', entity.id.toString());
      return this.http.put<Commande>(url, entity, { headers, params });
    } else {
      url = `${this.api}`;
      return this.http.post<Commande>(url, entity, { headers, params });
    }
  }

  reedit(entity: Commande): Observable<Commande> {
    let params = new HttpParams();
    let url = `${this.api}/dupliquer`;
    if (entity.id) {
      return this.http.post<Commande>(url, entity, { headers, params });
    } else {
      return this.http.post<Commande>(url, entity, { headers, params });
    }
  }

  changerStatus(entity: Commande): Observable<Commande> {
    let params = new HttpParams();
    let url = '';
    if (entity.id) {
      url = `${this.api}/${entity.id.toString()}/${entity.codeStatus.toString()}`;
      return this.http.put<Commande>(url, entity, { headers, params });
    } else {
      url = `${this.api}`;
      return this.http.post<Commande>(url, entity, { headers, params });
    }
  }

  saveForm(entityId: string, formdata: FormData): Observable<Commande> {
    let params = new HttpParams();
    let url = '';
    if (entityId) {
      url = `${this.api}/${entityId}`;
      params = new HttpParams().set('ID', entityId);
      return this.http.put<Commande>(url, formdata, { headers, params });
    } else {
      url = `${this.api}`;
      return this.http.post<Commande>(url, formdata, { headers, params });
    }
  }

  delete(entity: Commande): Observable<Commande> {
    let params = new HttpParams();
    let url = '';
    if (entity.id) {
      url = `${this.api}/${entity.id.toString()}`;
      params = new HttpParams().set('ID', entity.id.toString());
      return this.http.delete<Commande>(url, { headers, params });
    }
    return null;
  }
}

