// Voir la doc sur ce site http://bootboxjs.com/examples.html#bb-confirm  
declare var bootbox: any;

/**
 * Cette methode déclenche une popin de confirmation d'une simple action (Ex: une suppression d'un element)
 * @param message Le message de la popin de confirmation
 * @returns La promesse avec soit la valeur true ou false
 */
export function confirmAction(message: string): Promise<Boolean> {
    return new Promise<Boolean>((resolve, reject) => {        
        bootbox.confirm({
            message: message,
            centerVertical: true,
            buttons: {        
                confirm: {
                    label: 'Ok',
                    className: 'btn-primary'
                },
                cancel: {
                    label: 'Annuler'              
                }
            },
            callback: (result)  => {
                resolve(result);
            }
        });
    });
}