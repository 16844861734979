import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageService } from '../../../service/storage.service';
import { ProfilData, DroitUtilisateur, DroitUtilisateurFeature} from './ProfilData';

/**
 * 
 */ 
@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  private urlApi:string = environment.baseURLAPI + environment.baseAccountPort + 'account/profil';
  private urlDroitsApi:string = environment.baseURLAPI + environment.baseAccountPort + 'account/droits';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }

  countAll(): Observable<number> {
    const url = `${this.urlApi}/count`;
    return this.httpClient.get<number>(url);
  }

  getProfils(): Observable<ProfilData[]> {
    const url = `${this.urlApi}`;
    return this.httpClient.get<ProfilData[]>(url);
  }

  getAllProfils(): Observable<ProfilData[]> {
    const url = `${this.urlApi}/all`;
    return this.httpClient.get<ProfilData[]>(url);
  }

  getProfilById(id:string): Observable<ProfilData> {
    const url = `${this.urlApi}/` + id;
    return this.httpClient.get<ProfilData>(url);
  }

  getProfilsByOrganisation(organisationId:string): Observable<any> {
    const url = `${this.urlApi}/organisation/` + organisationId;
    return this.httpClient.get(url);
  }  

  getDomaines(): Observable<any> {
    const url = `${this.urlDroitsApi}`;
    return this.httpClient.get(url);
  }

  getSubActions(featureId: string): Observable<DroitUtilisateurFeature[]> {
    const url = `${this.urlDroitsApi}/sub-action/` + featureId;
    return this.httpClient.get<DroitUtilisateurFeature[]>(url);
  }

  getListFonctionsParFeatureDomaines(featureId: string): Observable<any> {
    const url = `${this.urlDroitsApi}/feature/${featureId}`;
    return this.httpClient.get(url);
  }
  
  createProfil(profilData:ProfilData): Promise<string>{
	  const url = `${this.urlApi}`;
    console.log("profilData => ", profilData);
    return this.httpClient.post(url, profilData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }
}
