import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageService } from '../../../service/storage.service';
import { NomenclatureData} from './NomenclatureData';
import { ImportNomenclatureData} from './ImportNomenclatureData';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class NomenclatureService {

  private urlApi:string = environment.baseURLAPI + environment.baseConfigPort + 'configuration/nomenclatureBudgetaire';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }

  getNomenclatures(): Observable<any> {
    const url = `${this.urlApi}`;
    return this.httpClient.get(url);
  }

  getNomenclaturesViseesImputables(): Observable<NomenclatureData[]> {
    const url = `${this.urlApi}/imputables-viser`;
    return this.httpClient.get<NomenclatureData[]>(url);
  }


  getNomenclatureByLignePrincipale(identifiant:string): Observable<NomenclatureData[]> {
    const url = `${this.urlApi}/lignes-secondaires/` + identifiant;
    return this.httpClient.get<NomenclatureData[]>(url);
  }

  getNomenclature(identifiant:string): Observable<NomenclatureData> {
    const url = `${this.urlApi}/` + identifiant;
    return this.httpClient.get<NomenclatureData>(url);
  } 

  changerStatus(nomenclatureSelectedList: NomenclatureData[]): Promise<string>{
    const url = `${this.urlApi}/valider`;
    return this.httpClient.post(url, nomenclatureSelectedList)
    .pipe(
    map((res: any) => {return res;})).toPromise();
  }

  createNomenclature(nomenclatureData: NomenclatureData): Promise<string>{
	  const url = `${this.urlApi}`;
    console.info("nomenclatureData >>>>> " + nomenclatureData);
    if(nomenclatureData.id !== null && nomenclatureData.id !== ''){
      return this.httpClient.put(url, nomenclatureData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
    }else{
      return this.httpClient.post(url, nomenclatureData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
    }
  }

  importerNomenclature(importNomenclatureData: ImportNomenclatureData): Promise<string>{
	  const url = `${this.urlApi}/importerNomenclatures`;
    console.info("importNomenclatureData >>>>> " + importNomenclatureData);
    var formData = new FormData();
    ( importNomenclatureData.fichierNomenclature ) && formData.append( "fichierNomenclature", importNomenclatureData.fichierNomenclature );
    return this.httpClient.post(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }

}
